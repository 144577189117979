import './App.css';
import profile from './Profile.PNG';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={profile} alt="profile" width={200} />
        <p>
          Please check back later, something awesome is coming!
        </p>
        <small>Antonio Correia</small>
      </header>
    </div>
  );
}

export default App;
